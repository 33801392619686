.n-chip {
  @apply relative flex h-min max-h-min w-fit items-center gap-x-3;

  // Force chip to inline when passed as inline embedded entry from rich text
  &[component-display="inline"] {
    @apply inline-flex;

    + .n-chip[component-display="inline"] {
      @apply ms-4;
    }
  }

  &-flip {
    @apply flex-row-reverse;
  }

  &-icon {
    @apply accent-stroke flex-shrink-0 object-contain;
  }

  &-titleslot {
    @apply block h-min;
  }

  &-title {
    @apply mb-0;
  }

  // * Customisations

  // Sizes
  &-xs {
    @apply text-xs;

    .n-chip-icon {
      @apply h-4 w-4;
    }
  }

  &-sm {
    @apply text-sm;

    .n-chip-icon {
      @apply h-4 w-4;
    }
  }
  &-md {
    @apply text-base;

    .n-chip-icon {
      @apply h-8 w-8;
    }
  }
  &-lg {
    @apply text-lg;

    .n-chip-icon {
      @apply h-10 w-10;
    }
  }

  &-xl {
    @apply text-xl;

    .n-chip-icon {
      @apply h-12 w-12;
    }
  }
}
