.n-mini-banner {
    @apply relative;

    .main-section {
        padding: 0
    }

    .n-mini-banner-slot {
        @apply bg-inherit;
    }

    .n-mini-banner-slot-1 {
        padding: 2rem;
    }

    .n-mini-banner-slot-container {
        @apply flex w-full flex-col gap-x-12 gap-y-8 lg:grid xl:grid-cols-2 xl:grid-rows-1;

        &.swap-asset {
            direction: rtl;
            @apply text-left;

            .n-mini-banner-buttons {
                @apply justify-end;
            }
        }

        h2,
        h3,
        h4,
        h5 {
            &:not(.with-border) {
                @apply pl-0;
            }
        }
    }

    .n-mini-banner-slot-2 {
        @apply relative mt-0 flex items-center justify-center xl:m-0;
        padding: 2rem;

        &.full-height {
            padding: 0;
        }

    }

    .n-mini-banner-buttons {
        @apply flex flex-col gap-y-4 lg:flex-row lg:gap-x-4 lg:gap-y-0 mt-8;
    }
}