.menu-blend {
	margin: 0 auto;
	padding: 0;

	overflow-x: hidden;
	// background-color: #000;
	color: #fff;
}

.menu-blend-container {
	background-color: #fff;
	left: -50vw;
	bottom: 0;
	right: -50vw;
	top: 0;
	height: 200px;
	position: relative;
}

.menu-blend-mode {
	mix-blend-mode: difference;
	position: fixed;
	z-index: 100;
	width: 100%;
	top: 0;
	padding: 0;
	height: 0;
	width: 100%;
	justify-content: flex-end;
	max-width: 1980px;
}

.menu-blend-nav {
	width: max-content;
	right: 170px;
	top: 40px;
}


	.backdrop {
		position: absolute;
		inset: 0;
		height: 200%;
		border-radius: 4px;
		// some older settings for reference
		// background: hsl(0deg 0% 100% / 0.1);
		// backdrop-filter: blur(8px) brightness(90%) saturate(140%);
		pointer-events: none;
		backdrop-filter: blur(80px);

		mask-image: linear-gradient(to bottom, black 0, black 50%, transparent 50%);
	}
	.backdrop-edge {
		opacity: 0.45;
		/* Set this to whatever you want for the edge thickness: */
		--thickness: 1px;
		position: absolute;
		inset: 0;
		height: 100%;
		transform: translateY(100%);
		background: hsl(0deg 0% 100% / 0.1);
		backdrop-filter: blur(6px) brightness(113%) saturate(120%);

		pointer-events: none;
		mask-image: linear-gradient(
			to bottom,
			black 0,
			black var(--thickness),
			transparent var(--thickness)
		);
	}