// TODO: potentially refactor tabs to scss
img.tab-img {
  @apply max-h-[450px] object-contain; // TODO: experiment with max height of image
}

// TODO: move btn-tab here
// This nesting is on purpose, its meant to style tabs within tabs
[component-name="nTabs"] {
  [component-name="nTabs"] {
    .btn-tab {
      @apply flex flex-1 justify-center rounded-none border-0 border-t text-center;
      &.active {
        @apply border-t-2 border-t-azure-100;
      }
    }
  }
  
  // Disable the padding when a section is dropped inside a tab
  .n-section-wrapper {
    @apply px-0 py-0 pt-4;
  }

  .hide-via-parent {
    @apply hidden;
  }
}
