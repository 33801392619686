@layer components {
  .n-grid {
    .n-tile {
      @apply h-full;
    }
  }

  // Row gaps
  .n-grid-row-gap-md {
    @apply gap-y-4;
  }
  .n-grid-row-gap-lg {
    @apply gap-y-4 lg:gap-y-6;
  }
  .n-grid-row-gap-xl {
    @apply gap-y-4 lg:gap-y-8;
  }
  .n-grid-row-gap-section {
    @apply gap-y-4 lg:gap-y-20;
  }

  // Column gaps
  .n-grid-col-gap-md {
    @apply gap-x-4;
  }
  .n-grid-col-gap-lg {
    @apply gap-x-4 lg:gap-x-6;
  }
  .n-grid-col-gap-xl {
    @apply gap-x-4 lg:gap-x-8;
  }
}
