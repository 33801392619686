@layer components {
  .image-wrapper {
    @apply lg:w-full overflow-hidden;
  }

  .image-wrapper::after {
    content: '';
    @apply absolute top-0 left-0 w-full h-full pointer-events-none opacity-50;
  }

  .tint-navy-100::after {
    @apply bg-navy-100;
  }

  .tint-navy-70::after {
    @apply bg-navy-70;
  }

  .tint-white-100::after {
    @apply bg-white-100;
  }

  .tint-white-90::after {
    @apply bg-white-90;
  }

  .tint-azure-100::after {
    @apply bg-azure-100;
  }
}
