// @layer components {
.rich-text {
  > {
    h1 {
      @apply headline-xl;
    }

    h2 {
      @apply headline-lg;
    }

    h3 {
      @apply headline-md;
    }

    h4 {
      @apply headline-sm;
    }

    h1, h2, h3, h4 {
      @apply mb-2;
    }

    span {
      @apply pb-3 tracking-wide;
    }

    b,
    strong {
      @include fontMedium;
      @apply font-semibold;
    }

    img {
      @apply py-8;
    }

    p:empty {
      @apply pt-4;
      &:last-child {
        @apply pt-0;
      }
    }

    hr {
      @apply mb-10 border-[1px] border-solid border-navy-100;
    }

    ul,
    ol {
      @apply mb-4 pl-4;
    }

    ul {
      @apply list-disc;
      &.custom-bullets {
        @apply list-none pl-0;
      }
    }

    ol {
      @apply list-decimal;
    }

    .video-wrapper {
      width: 100%;
      max-width: 768px; // set max width of embedded video
      .video-container {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: 56.25%;

        iframe,
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-width: 100%;
        }
      }
    }

    p > a {
      @apply underline;
      &:not(.btn, .custom-anchor) {
        @apply hover:text-azure-100;
      }
    }

    a {
      @apply underline;
      &:not(.btn, .custom-anchor) {
        @apply hover:text-azure-100;
      }
    }

    // Spacing between embedded entries
    [component-name="nWidget"],
    [component-name="nChip"] {
      @apply mb-4 last:mb-0;
    }

    [component-name="nTile"] {
      img {
        @apply py-0;
      };
    }
  }

  // Disable image spacing for rich text in Tiles descriptions
  &.n-tile-description > img {
    @apply py-0;
  }
}
