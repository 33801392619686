@layer utilities {
  .with-sup {
    @apply relative;

    span {
      // @apply pl-2.5;
    }

    sup {
      @apply top-0 pt-1;
    }
  }
  .bg-gradient-glass-to-left {
    position: relative;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: inherit;
      backdrop-filter: blur(5.5px);
      -webkit-backdrop-filter: blur(5.5px);
      mask-image: linear-gradient(to left, black 0%, transparent 100%);
      -webkit-mask-image: linear-gradient(to left, black 0%, transparent 100%);
    }
  }
  .bg-gradient-glass-to-right {
    position: relative;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: inherit;
      backdrop-filter: blur(5.5px);
      -webkit-backdrop-filter: blur(5.5px);
      mask-image: linear-gradient(to right, black 0%, transparent 100%);
      -webkit-mask-image: linear-gradient(to right, black 0%, transparent 100%);
    }
  }

  .bg-glass {
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

.transition-max-height {
  overflow: hidden;
  transition: max-height 0.5s all;
}

.hide {
  @apply hidden;
}

.grid-center-parent {
  @apply grid grid-cols-1 grid-rows-1;

  & > * {
    @apply col-span-1 col-start-1 row-span-1 row-start-1;
  }
}

.price-down {
  @apply text-price-down;
  &::before {
    @apply pr-1.5;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M5.78566 10.1283V2.33545H6.78566V10.1283L9.93211 6.9819L10.6392 7.689L6.28566 12.0426L1.93211 7.689L2.63921 6.9819L5.78566 10.1283Z' fill='%23CD5D0F'/%3E%3C/svg%3E");
  }
}

.price-up {
  @apply text-price-up;

  &::before {
    @apply pr-1.5;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M6.64284 3.54263V11.8355H5.64284V3.54263L2.49639 6.68908L1.78928 5.98197L6.14284 1.62842L10.4964 5.98197L9.78928 6.68908L6.64284 3.54263Z' fill='%232A7D55'/%3E%3C/svg%3E");
  }
}

// ?? TODO: What is this for
.device-show {
  @apply hidden;

  &.type {
    &-desktop {
      &:not(.flex-show) {
        body.desktop & {
          @apply block;
        }
      }
      &.flex-show {
        body.desktop & {
          @apply flex;
        }
      }
    }
    &-tablet {
      &:not(.flex-show) {
        body.tablet & {
          @apply block;
        }
      }
      &.flex-show {
        body.tablet & {
          @apply flex;
        }
      }
    }
    &-mobile {
      &:not(.flex-show) {
        body.mobile & {
          @apply block;
        }
      }
      &.flex-show {
        body.mobile & {
          @apply flex;
        }
      }
    }
  }

  &.os {
    &-android {
      body.os-android & {
        @apply block;
      }
    }
    &-ios {
      body.os-ios & {
        @apply block;
      }
    }
    &-other {
      body.os-other & {
        @apply block;
      }
    }

    &-other-desktop {
      body.os-other-desktop & {
        @apply block;
      }
    }
    &-mac {
      body.os-mac & {
        @apply block;
      }
    }
  }
}
