@layer components {
  .n-title {
    // * General styles
    @apply relative h-full sm:col-span-2 lg:col-span-1 lg:mb-0 pe-4;

    &-direction-row {
      @apply md:flex md:justify-between md:gap-x-6;
      &.n-title-left {
        .n-title-text {
          @apply lg:max-w-none;
        }
        .n-title-description {
          @apply lg:max-w-full md:ms-auto;
        }
      }
    }

    &-direction-column {
    }

    &-smalltext {
      @apply mb-6;
    }

    &-icon {
      @apply mb-6 max-h-20 w-auto ps-2;
    }

    .n-title-description {
      > p {
        @apply mb-4 text-[length:inherit];
        .n-chip {
          p {
            @apply mb-0;
          }
        }
      }
      ul {
        @apply mb-4;
        li {
          @apply text-[length:inherit];
        }
      }
    }

    // * Center Alignment
    &-center {
      .n-title-icon {
        @apply mx-auto ps-0;
      }
      .n-title-smalltext {
        @apply text-center;
      }

      .n-title-text {
        @apply mb-4 text-center;
      }

      .n-title-description {
        @apply mx-auto text-center xl:max-w-3xl;
        ul {
          @apply list-inside;
        }

        .btn {
          @apply mx-auto;
        }
      }

      .n-title-buttons {
        @apply mt-10 flex flex-col items-center gap-4 lg:flex-row lg:items-start lg:justify-center;
      }
    }

    // * Left Alignment
    &-left {
      .n-title-smalltext {
        @apply ms-2;
      }

      .n-title-text {
        @apply relative -ms-6 mb-6 border-s-8 border-solid ps-6 text-start lg:max-w-[80%];
      }

      .n-title-description {
        @apply ms-2 lg:max-w-[80%];

        ul {
          @apply ps-4;
        }
      }

      .n-title-buttons {
        @apply mt-10 ms-2 flex w-min flex-col gap-4;
      }
    }
  }
}
