.n-banner {
  @apply relative;
  // Global
  .n-banner-blur {
    @apply after:absolute after:left-0 after:top-0 after:block after:h-full after:w-full after:backdrop-blur-xl;
  }

  .n-banner-cover {
    @apply absolute h-full w-full object-cover;
  }

  // Horizontal view specific styling
  &.n-banner-view-horizontal {
    .n-banner-slot {
      @apply bg-inherit;
    }

    .n-banner-full-bg {
      @apply absolute left-0 top-0 h-full w-full;

      // Flip image for full bg banners on RTL
      [dir="rtl"] & {
        .image-wrapper img {
          @apply -scale-x-[1];
        }
      }
    }

    .n-banner-slot-container {
      @apply flex w-full flex-col gap-x-12 gap-y-8 lg:grid lg:px-6 xl:grid-cols-2 xl:grid-rows-1;
    }

    .n-banner-slot-1 {
    }

    .n-banner-slot-2 {
      @apply relative -mb-12 -ml-[30px] -mr-6 mt-0 flex items-center justify-center xl:m-0;

      [component-name="nAssetWrapper"] {
        @apply p-8 lg:p-0;
      }
    }

    .n-banner-slot-1-bg {
      @apply absolute bottom-0 left-0 top-0 block h-1/2 w-full xl:bottom-0 xl:top-auto xl:h-full xl:w-1/2;
      // ? temporary fix for blur effect on mobile slot 1
      &.n-banner-blur {
        @apply top-0 h-full bg-black/25 after:hidden xl:block xl:bg-transparent xl:after:block;
      }

      &.n-banner-blur-with-dark-gradient {
        @apply top-0 h-full after:hidden bg-black/25 xl:block xl:bg-gradient-to-r xl:from-black/85 xl:via-black/25 xl:to-transparent xl:bg-[length:100%_100%] xl:after:block;
      }

      // Move slot 1 to the right on RTL and flip image
      [dir="rtl"] & {
        @apply right-0 left-auto;
        .image-wrapper:not(.no-flip) img {
          @apply -scale-x-[1];
        }
      }
    }

    .n-banner-slot-2-bg {
      @apply absolute bottom-0 right-0 top-auto hidden h-1/2 w-full lg:block xl:bottom-0 xl:top-auto xl:h-full xl:w-1/2;

      // Move slot 2 to the left on RTL and flip image
      [dir="rtl"] & {
        @apply right-auto left-0;
        .image-wrapper:not(.no-flip) img {
          @apply -scale-x-[1];
        }
      }
    }
  }

  // Vertical view specific styling
  &.n-banner-view-vertical {
    .n-banner-slot-container {
      @apply grid gap-y-12;
    }

    .n-banner-slot-1 {
      @apply relative;
      &-bg {
        @apply relative h-auto w-full;
        > img {
          @apply h-auto w-full object-cover;
        }

        // Flip image for slot 1 bg banners on RTL
        [dir="rtl"] & {
          .image-wrapper:not(.no-flip) img {
            @apply -scale-x-[1];
          }
        }
      }
    }

    .n-banner-slot-2 {
      &-bg {
        @apply relative h-auto w-full;
        > img {
          @apply h-auto w-full object-cover;
        }

        // Flip image for slot 2 bg banners on RTL
        [dir="rtl"] & {
          .image-wrapper:not(.no-flip) img {
            @apply -scale-x-[1];
          }
        }
      }
    }

    // Full banner background image
    .n-banner-full-bg {
      @apply absolute left-0 top-0 h-full w-full;
      > img {
        @apply absolute h-full w-full object-cover;
      }

      // Flip image for full bg banners on RTL
      [dir="rtl"] & {
        .image-wrapper:not(.no-flip) img {
          @apply -scale-x-[1];
        }
      }
    }

    // * Slot content positioning
    // ? If slot content, apply to slot bg image.
    // ? When there is no slot content (e.g. slot1, slot2), the bg image is relative positioned.
    .n-banner-absolute {
      @apply absolute left-0 top-0 h-full w-full;
    }
  }
}
