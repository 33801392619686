@layer components {
  @import "uplot/dist/uPlot.min.css";

  .comp-table {
    .trend-uplot {
      @apply relative overflow-hidden;
      canvas {
        @apply absolute left-0 top-0 h-[25px] w-[120px];
      }
    }
  }

  .uplot {
    @apply w-min;
    canvas {
      @apply absolute left-0 top-0 h-full w-full;
    }
    .u-over {
      @apply absolute;
    }

    .uplotlinechart-tooltip {
      .ulc-tooltip-heading {
        @apply font-semibold uppercase;
      }
    }
  }
}