@layer components {
  .swipe-icon {
    @apply pointer-events-none absolute -top-3 right-0 z-[2] h-full w-full text-end text-xxs uppercase;

    &:not(.no-fade)::before {
      @apply absolute right-0 h-full w-[10%];
      content: "";
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cmc-table {
    @apply h-full overflow-x-auto overflow-y-hidden pb-10;

    // ? Experimental. Prevents extra padding on tables where there is no child elements underneath.
    // ? In this case contentful rte likes to add an empty <p> tag underneath table so we need to remove the padding.
    // TODO: Remove if needed, see costs page for example
    &:nth-last-child(2) {
      // check if table is the second to last child
      &:has(+ p:empty) {
        // check if table has an empty p tag as a sibling
        @apply pb-0;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    table {
      &.sticky-first-column {
        * {
          border: none;
          @apply whitespace-normal;
        }

        @apply pr-6;

        tr > th:first-child {
          &:not(.show-more) {
            @apply sticky left-0 z-10;
          }
        }

        // Sticky colum box shadow effect
        tr > td:first-child {
          &:not(.show-more) {
            @apply sticky left-0 z-10;

            &::after {
              content: "";
              @apply absolute -right-[20px] bottom-0 top-0 w-[20px] border-l-[2px] border-solid;
            }
          }
        }
      }

      @apply table h-full min-w-full table-fixed border-separate;

      th,
      td {
        @apply py-2 text-left;
      }

      thead {
        tr {
          @apply w-full;

          th {
            @apply select-none text-nowrap pb-1 text-start align-baseline text-xxs font-light uppercase tracking-wider;

            // TODO: Graeme reminder to refactor
            .th-container {
              @apply flex items-center gap-x-1 text-start;

              svg {
                @apply self-auto;
              }
            }

            p {
              @apply w-full text-start;
            }

            &.sortable {
              @apply cursor-pointer;
            }

            &:first-child {
              p {
                @apply w-full text-start;
              }
            }

            .th-container {
              @apply flex items-center justify-center;
            }

            &:first-child {
              .th-container {
                @apply justify-start;
              }
            }
          }

          td {
            @apply px-3;
          }
        }
      }

      tbody {
        tr {
          &.show-more {
            @apply right-0 flex h-min w-full py-2;

            td {
              @apply absolute left-0 right-0 min-w-full cursor-auto;

              p {
                @apply right-[50%] mx-auto w-min cursor-pointer text-nowrap rounded-full px-4 py-2 text-sm;
              }
            }
          }

          td {
            @apply whitespace-pre-wrap text-wrap bg-inherit px-4 text-left first:whitespace-nowrap first:text-start;

            &:first-child {
              text-wrap: unset;
              z-index: 5;
            }

            a {
              @apply text-nowrap;
            }

            &:not(:first-child) {
              @apply relative z-[1];
            }

            p {
              @apply mb-0 min-h-min;
            }
          }
        }
      }
    }

    &.dynamic-table {
      table {
        thead {
          tr {
            th {
              @apply text-nowrap text-center first:text-start;

              p {
                @apply text-center;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              @apply text-nowrap text-center first:min-w-[150px] first:text-start;

              a {
                @apply text-wrap lg:text-nowrap;
              }
            }
          }
        }
      }
    }
  }
}
