header {
  @apply sticky top-0 z-[50] pointer-events-none max-w-[100vw];

  // * Desktop Megamenu
  .n-megamenu {
    #searchbar {
      @apply max-w-[325px];

      #searchbar-dropdown {
        @apply max-w-[325px] max-h-[650px];
      }
    }

    @apply top-0 z-[50] w-full bg-white pointer-events-auto;
    .links-wrapper {
      @apply relative left-0 top-auto flex h-auto min-h-0 flex-row items-center px-0 text-xs text-navy-100;
    }
    .first-level-container {
      @apply relative;
    }
    .first-level {
      @apply relative z-50 inline-block w-full rounded-full px-4 py-2 uppercase tracking-[0.5px] no-underline hover:bg-white-85;
      &:after {
        @apply absolute right-1 top-7 h-5 w-5 rotate-0 transition-transform;
      }
      &.active {
        @apply text-azure-100 hover:text-azure-100;
      }
      &.open {
        &:after {
          @apply rotate-180;
        }
      }
    }
    .second-level-container {
      @apply absolute left-0 top-10 z-[999999] w-max rounded-b-3xl bg-white;
    }

    .second-level-container-list {
      @apply mt-0 rounded-b-3xl bg-white pb-4 pt-4 shadow-lg;
    }
    .second-level {
      @apply mb-0 block px-8 py-2 text-sm no-underline first:-mt-2 hover:bg-azure-20;
      &.active {
        @apply text-azure-100 hover:text-azure-100;
      }
      &-label {
        @apply mb-0 px-8 pt-4 text-xs text-xxs uppercase text-navy-20 first:pt-0;
      }
    }

    a {
      &:not(.btn) {
        @apply hover:text-inherit;
      }
    }
  }

  // * Mobile Megamenu
  .n-megamenu-mobile {
    @apply top-0 z-[50] w-full bg-white  pointer-events-auto;

    #searchbar {
      @apply max-h-[100vh-300px];

      &.float-to-top {
        @apply fixed top-10;
      }

      @apply max-w-[calc(100vw-30px)];

      #searchbar-dropdown {
        @apply max-w-[calc(100vw-30px)] max-h-[calc(100vh-55px)];
      }
    }

    .links-wrapper {
      @apply h-screen flex-col px-6 text-xl text-navy-100;
    }
    .first-level-container {
      @apply relative border-b border-b-navy-100;
    }
    .first-level {
      @apply relative block w-full py-4 tracking-[0.5px] no-underline;
      &:after {
        @apply absolute right-1 top-5 h-5 w-5 rotate-0 transition-transform content-[url("https://assets.cmcmarkets.com/neptune/images/common/chevron-down.svg")];
      }
      &.active {
        @apply text-azure-100 hover:text-azure-100;
      }
      &.open {
        &:after {
          @apply rotate-180;
        }
      }
    }
    .second-level-container {
      @apply z-50 w-full;
    }
    .second-level-container-list {
      @apply pb-4 pt-2;

      &:empty {
        @apply hidden;
      }
    }
    .second-level {
      @apply mb-0 block px-4 py-2 no-underline first:-mt-2 hover:bg-azure-20;
      &.active {
        @apply text-azure-100 hover:text-azure-100;
      }
      &-title {
        @apply px-4 pt-10 text-xs uppercase text-navy-20 first:pt-0;
      }
    }
    .btn-ios {
      @apply flex max-w-[175px] items-center justify-center gap-2 rounded-lg bg-navy-100 px-4 py-3 text-xs text-white no-underline;
    }
    .btn-android {
      @apply flex max-w-[175px] items-center justify-center gap-2 rounded-lg bg-navy-100 px-4 py-3 text-xs text-white no-underline;
    }
    .btn-demo {
      @apply flex max-w-[175px] items-center justify-center gap-2 rounded-lg border border-navy-100 bg-white px-4 py-3 text-xs text-navy-100 no-underline;
    }
    a {
      &:not(.btn) {
        @apply hover:text-inherit;
      }
    }
  }
}
