// For base table styling, see 'table.scss' in components folder

// Table For White 100 Theme
@mixin table-on-white-100 {
  .swipe-icon {
    &::before {
      background: linear-gradient(to left, rgba(255, 255, 255, 0) 75%);
    }
  }

  .cmc-table {
    table {
      // Sticky colum box shadow effect
      tr > td:first-child {
        &:not(.show-more) {
          &::after {
            background: linear-gradient(to right, rgba(0, 0, 0, 0.15), transparent);
          }
        }
      }
    }

    thead {
      tr {
        th,
        td {
          @apply first:bg-white;
        }
      }
    }

    tbody {
      tr {
        @apply odd:bg-white-95 even:bg-white;

        &.even {
          @apply bg-black;
        }

        &.show-more {
          @apply bg-transparent;

          td {
            p {
              @apply bg-azure-100 text-white;

              &:hover {
                @apply bg-azure-70;
              }
            }
          }
        }

        &:hover {
          &:not(.show-more) {
            td {
              @apply bg-white-90;
            }
          }
        }

        td {
          @apply bg-inherit;
        }
      }

      tr:not(.show-more):nth-child(odd) {
        @apply bg-white-95;
      }

      tr:not(.show-more):nth-child(even) {
        @apply bg-white;
      }
    }
  }
}

// Table For White 90 Theme
@mixin table-on-white-90 {
  .swipe-icon {
    &::before {
      content: "";
      background: linear-gradient(
        to left,
        rgb(230, 230, 230) 0%,
        rgb(230, 230, 230) 20%,
        rgba(255, 255, 255, 0) 75%
      );
    }
  }

  .cmc-table {
    table {
      &.sticky-first-column {
        tr > td:first-child {
          &:not(.show-more) {
            &::after {
              @apply border-white-90;
            }
          }
        }
      }

      thead {
        tr {
          th,
          td {
            @apply first:bg-white-90;
          }
        }
      }

      tbody {
        tr {
          &:not(.show-more) {
            &:nth-child(odd) {
              @apply bg-white-95;
            }

            &:nth-child(even) {
              @apply bg-white;
            }

            &:hover {
              td {
                @apply bg-white-85;
              }
            }
          }
        }
      }
    }
  }
}

// Table For Navy 100 Theme
@mixin table-on-navy-100 {
  .btn-tab {
    @apply text-white hover:text-white hover:bg-navy-70 focus:bg-navy-70;

    &.active {
      @apply border-navy-20 bg-navy-20 text-white hover:border-azure-40;
    }
  }

  .swipe-icon {
    &::before {
      content: "";
      background: linear-gradient(
        to left,
        rgb(16, 22, 38) 0%,
        rgb(16, 22, 38) 20%,
        rgba(255, 255, 255, 0) 75%
      );
    }
  }

  .cmc-table {
    table {
      &.sticky-first-column {
        tr > td:first-child {
          &:not(.show-more) {
            &::after {
              @apply border-navy-100;
            }
          }
        }
      }

      thead {
        tr {
          th,
          td {
            @apply first:bg-navy-100;

            .th-container {
              @apply stroke-navy-70;
            }

            &.sortable {
              @apply bg-navy-100;
            }
          }
        }
      }

      tbody {
        tr {
          &:not(.show-more) {
            &:nth-child(odd) {
              @apply bg-navy-70;
            }

            &:nth-child(even) {
              @apply bg-navy-100;
            }

            &:hover {
              td {
                @apply bg-navy-40;
              }
            }
          }
        }
      }
    }
  }
}

// Table For Navy 70 Theme
@mixin table-on-navy-70 {
  .btn-tab {
    @apply text-white hover:text-white hover:bg-navy-40 hover:border-azure-100;

    &.active {
      @apply border-navy-20 bg-navy-20 text-white hover:border-azure-40;
    }
  }

  .swipe-icon {
    &::before {
      content: "";
      background: linear-gradient(
        to left,
        rgb(50 60 89) 0%,
        rgb(50 60 89) 20%,
        rgba(255, 255, 255, 0) 75%
      );
    }
  }

  .cmc-table {
    table {
      &.sticky-first-column {
        tr > td:first-child {
          &:not(.show-more) {
            &::after {
              @apply border-navy-70;
            }
          }
        }
      }

      thead {
        tr {
          th,
          td {
            @apply text-white-90 first:bg-navy-70;

            .th-container {
              @apply stroke-navy-70;
            }

            &.sortable {
              @apply bg-navy-70;
            }
          }
        }
      }

      tbody {
        tr {
          &:not(.show-more) {
            &:nth-child(odd) {
              @apply bg-navy-40;
            }

            &:nth-child(even) {
              @apply bg-navy-70;
            }

            &:hover {
              td {
                @apply bg-navy-20;
              }
            }
          }
        }
      }
    }
  }
}

// Table For Transparent Light
@mixin table-on-transparent-light {
  .swipe-icon {
    &::before {
      content: "";
      background: linear-gradient(
        to left,
        rgb(230, 230, 230) 0%,
        rgb(230, 230, 230) 20%,
        rgba(255, 255, 255, 0) 75%
      );
    }
  }

  .cmc-table {
    table {
      @apply border-collapse;

      &.sticky-first-column {
        tr > td:first-child {
          &:not(.show-more) {
            &::after {
              @apply border-white-90;
            }
          }
        }
      }

      thead {
        tr {
          th,
          td {
            @apply first:bg-white-90 pl-4;
          }
        }
      }

      tbody {
        tr {
          &:not(.show-more) {
            &:nth-child(odd) {
              @apply bg-white-95;
            }

            &:nth-child(even) {
              @apply bg-white;
            }

            &:hover {
              td {
                @apply bg-white-85;
              }
            }
          }
        }
      }
    }
  }
}

// Table For Transparent Dark
@mixin table-on-transparent-dark {
  .btn-tab {
    @apply text-white hover:text-white;

    &.active {
      @apply border-navy-20 bg-navy-20 text-white hover:border-azure-40;
    }
  }

  .swipe-icon {
    &::before {
      content: "";
      background: linear-gradient(
        to left,
        rgb(16, 22, 38) 0%,
        rgb(16, 22, 38) 20%,
        rgba(255, 255, 255, 0) 75%
      );
    }
  }

  .cmc-table {
    table {
      @apply border-collapse;

      &.sticky-first-column {
        tr > td:first-child {
          &:not(.show-more) {
            &::after {
              @apply border-navy-100;
            }
          }
        }
      }

      thead {
        tr {
          th,
          td {
            @apply bg-navy-100 pl-4;

            .th-container {
              @apply stroke-navy-70;
            }

            &.sortable {
              @apply bg-navy-100;
            }
          }
        }
      }

      tbody {
        tr {
          &:not(.show-more) {
            &:nth-child(odd) {
              @apply bg-navy-70;
            }

            &:nth-child(even) {
              @apply bg-navy-100;
            }

            &:hover {
              td {
                @apply bg-navy-40;
              }
            }
          }
        }
      }
    }
  }
}
