/**
 * This stylesheet imports various Tailwind CSS base, components, and utilities,
 * as well as custom styles for layout, utilities, vendors, and components.
 *
 * Tailwind CSS:
 * - @tailwind base: Includes Tailwind's base styles.
 * - @tailwind components: Includes Tailwind's component styles.
 * - @tailwind utilities: Includes Tailwind's utility classes.
 *
 * Custom Imports:
 * - Layout: Custom layout styles for main structure, typography, header, and footer.
 * - Utilities: Custom utility styles for nesting levels, theming, lines, and library functions.
 * - Vendors: Vendor-specific styles for Tippy.js and uPlot.
 * - Components: Custom component styles for various UI elements such as accordion, banner, buttons, card, chip, forms, grid, section, rich-text, table, tabs, tile, title, document, asset-wrapper, and did.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

// ? For review TODO: REVIEW!
// @import "layout";
// @import "device-show";
// @import "accordion";
// @import "breadcrumbs";
// @import "tables";
// @import "forms";

// Layout
@import "layout/main";
@import "layout/typography";
@import "layout/header";
@import "layout/footer";

// Utilities
@import "utilities/nest-levels";
@import "utilities/theming/main";
@import "utilities/lines";
@import "utilities/lib";
@import "utilities/glow";
@import "utilities/glass";

// Vendors
@import "vendors/tippy";
@import "vendors/uplot";
@import "components/searchbar";

// Components
@import "components/mini-banner";
@import "components/accordion";
@import "components/banner";
@import "components/buttons";
@import "components/card";
@import "components/chip";
@import "components/forms";
@import "components/grid";
@import "components/section";
@import "components/rich-text";
@import "components/table";
@import "components/tabs";
@import "components/tile";
@import "components/title";
@import "components/document";
@import "components/asset-wrapper";
@import "components/did";
@import "components/template";

@import "components/internal-help-dialog";
