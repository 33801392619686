// * Base button styling. For button themes, see utilities/themes.scss
@layer components {
  // ? n-button parent
  .n-button {
    @apply block;
    // * When used as inline embedded component inside rich text
    &[component-display="inline"] {
      @apply mb-4 lg:inline-flex mr-2 last:mr-0 last:mb-0;
      .btn {
        @apply inline-flex;
      }
    }
  }

  // ? Base styling here
  .btn {
    @apply flex w-fit cursor-pointer items-center self-start whitespace-nowrap rounded-full px-6 py-4 text-sm normal-case tracking-[0.16px] no-underline transition-all;
    @include fontMedium;

    // * Theme agnostic colors
    &-azure-light {
      @apply border border-azure-100 bg-azure-20 stroke-navy-100 text-navy-100;
    }

    &-primary-outline {
      @apply border border-azure-100 bg-transparent stroke-navy-100 text-navy-100 hover:border-azure-70 hover:bg-azure-20;
    }

    // * Sizes
    &-xxl {
      @apply px-[4.5rem] py-8 text-xl;
    }

    &-xl {
      @apply px-12 py-6 text-lg;
    }

    &-lg {
      @apply px-9 py-[21px] text-base;
    }

    &-md {
      @apply px-6 py-4 text-sm;
    }

    &-sm {
      @apply px-4 py-[9px] text-xs;
    }

    &-xs {
      @apply px-3 py-[7px] text-xs;
    }

    &-xxs {
      @apply px-2 py-[5px] text-xs;
    }

    // * Misc
    &-with-icon {
      @apply space-x-[14px];

      [dir="rtl"] & {
        @apply space-x-reverse;
      }
    }

    &-only-icon {
      @apply p-[18px];
    }

    &-is-inline {
      @apply mt-2;
    }
  }
}
