
@layer components {
  .error {
    @apply border-red-500 border-[5px] animate-pulse bg-glass opacity-70 border-solid shadow-xl;
  }
  
  .info {
    @apply border-blue-500 animate-pulse bg-glass opacity-70 border-solid shadow-xl;
  }

  /* Default level */
  .level-99 {
    .level-99-container {
      @apply -left-[2.5px] -top-[2.5px] h-[calc(100%+5px)] w-[calc(100%+5px)];
    }

    .level-99-index {
      @apply z-[1];
    }

    .level-99-bg {
      @apply bg-teal-200;
    }

    .level-99-bg-light {
      @apply bg-teal-50;
    }

    .level-99-border {
      @apply border-teal-500;
    }

    .level-99-stroke {
      @apply stroke-teal-500;
    }
  }

  .level-1 {
    .level-1-container {
      @apply h-full w-full p-2;
    }

    .level-1-index {
      @apply z-[3];
    }

    .level-1-bg {
      @apply bg-purple-200;
    }

    .level-1-bg-light {
      @apply bg-purple-50;
    }

    .level-1-border {
      @apply border-purple-500;
    }

    .level-1-stroke {
      @apply stroke-purple-500;
    }

    .level-1-error-msg {
      @apply bg-white text-purple-600;
    }

    .level-1-error-bg {
      @apply bg-purple-900;
    }

    .level-1-text {
      @apply text-purple-500;
    }
  }


  .level-2 {
    .level-2-container {
      @apply -left-[12px] -top-[12px] h-[calc(100%+24px)] w-[calc(100%+24px)] z-40;
    }

    .level-2-index {
      @apply z-[4];
    }

    .level-2-bg {
      @apply bg-emerald-200;
    }

    .level-2-bg-light {
      @apply bg-emerald-50;
    }

    .level-2-border {
      @apply border-emerald-500;
    }

    .level-2-stroke {
      @apply stroke-emerald-500;
    }

    .level-2-error-msg {
      @apply bg-white text-red-600;
    }

    .level-2-error-bg {
      @apply bg-green-900/60;
    }

    .level-2-text {
      @apply text-emerald-500;
    }
  }

  [component-name="nTitle"]{
    &.total-slots-2, &.total-slots-3, &.total-slots-4 {
      .level-2 {
        .level-2-container {
          @apply w-full ;
        }
      }
    }
  }

  [component-name="nCard"] {
    &.total-slots-2, &.total-slots-3, &.total-slots-4 {
      .level-2 {
        .level-2-container {
          @apply h-full w-full top-0 left-0 ;
        }
      }
    }
  }

  .level-3 {
    .level-3-container {
      // @apply -top-[3px] -left-[4px]  h-[calc(100%+6px)] w-[calc(100%+7px)];
      @apply h-full w-full;
    }

    .level-3-index {
      @apply z-[5];
    }

    .level-3-bg {
      @apply bg-blue-200;
    }

    .level-3-bg-light {
      @apply bg-blue-50;
    }

    .level-3-border {
      @apply border-blue-500;
    }

    .level-3-stroke {
      @apply stroke-blue-500;
    }

    .level-3-error-msg {
      @apply bg-white text-blue-600;
    }

    .level-3-error-bg {
      @apply bg-blue-900/60;
    }

    .level-3-text {
      @apply text-blue-500;
    }
  }

  // Hopefully we should never go past this comment
  .level-4 {
    .level-4-container {
      @apply left-[2.5px] top-[2.5px] h-[calc(100%-5px)] w-[calc(100%-5px)];
    }

    .level-4-index {
      @apply z-[6];
    }

    .level-4-bg {
      @apply bg-amber-200;
    }

    .level-4-bg-light {
      @apply bg-amber-50;
    }

    .level-4-border {
      @apply border-amber-500;
    }

    .level-4-stroke {
      @apply stroke-amber-500;
    }

    .level-4-error-msg {
      @apply bg-white text-amber-600;
    }

    .level-4-error-bg {
      @apply bg-amber-900/60;
    }

    .level-4-text {
      @apply text-amber-500;
    }
  }

  .level-5 {
    .level-5-container {
      @apply left-0 top-0 h-[100%] w-[100%];
    }

    .level-5-index {
      @apply z-[7];
    }

    .level-5-bg {
      @apply bg-lime-200;
    }

    .level-5-bg-light {
      @apply bg-lime-50;
    }

    .level-5-border {
      @apply border-lime-500;
    }

    .level-5-stroke {
      @apply stroke-lime-500;
    }

    .level-5-error-msg {
      @apply bg-white text-lime-600;
    }

    .level-5-error-bg {
      @apply bg-lime-900/60;
    }

    .level-5-text {
      @apply text-lime-500;
    }

  }

  .level-6 {
    .level-6-container {
      @apply left-0 top-0 h-[100%] w-[100%];
    }

    .level-6-index {
      @apply z-[8];
    }

    .level-6-bg {
      @apply bg-fuchsia-200;
    }

    .level-6-bg-light {
      @apply bg-fuchsia-50;
    }

    .level-6-border {
      @apply border-fuchsia-500;
    }

    .level-6-stroke {
      @apply stroke-fuchsia-500;
    }

    .level-6-error-msg {
      @apply bg-white text-fuchsia-600;
    }

    .level-6-error-bg {
      @apply bg-fuchsia-900/60;
    }

    .level-6-text {
      @apply text-fuchsia-500;
    }
  }
}
