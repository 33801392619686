.n-card-container {
  @apply min-h-full h-full max-w-[550px] relative;
  // perspective: 1500px;

  .n-card {
    @apply h-full w-full flex max-w-[550px] flex-col px-4 xl:px-5 pt-4 pb-5 rounded-xl relative;

    &.card-animation {
      position: relative;

      transition-duration: 300ms;
      transition-property: transform, box-shadow;
      transition-timing-function: ease-out;
      transform: rotate3d(0);

      &:hover {
        transition-duration: 150ms;
        box-shadow: 0 5px 20px 5px #00000044;
      }
    }

    &.has-theme {
      @apply shadow-lg border border-solid border-gray-400;
    }

    &.n-card-clickable {
      @apply transition-all duration-200 hover:shadow-xl hover:border-azure-100;
    }

    &.img-aspect-3\/2 {
      .n-card-image {
        @apply aspect-[3/2];
      }
    }

    &-tileslot {
      @apply mb-8;
    }

    &-imageslot {
      @apply mb-4 border-b pb-6;
    }

    &-image {
      @apply h-full;
    }

    &-smalltext {
      @apply mb-4;
    }

    &-title {
      @apply mb-4;
    }

    &-buttons {
      @apply mt-8 flex flex-col gap-3;
    }

    // News card overrides
    &.n-card-news {
      .n-card-tileslot {
        @apply min-h-[102px];
      }
      .n-card-imageslot {
        @apply h-full min-h-[275px] max-h-[275px] overflow-hidden;
      }
      .n-card-image {
        @apply aspect-video w-full object-cover object-center; //experimental as thumbnails are random sizes :(
      }
    }
  }
}
