// Instructions
// To target search input: &:first-child .wrapper AND &:first-child .wrapper input
// To target search icon: .search-icon
// To target search results: ul
// To target search result items: ul li

.instrument-search {
  // Base classes
  ul {
    li {
      @apply cursor-pointer hover:bg-azure-40;
    }
  }

  .wrapper {
    @apply border border-azure-100 bg-azure-10 ps-0;
    svg {
      @apply hidden; //hides the default search icon provided by the vendor
    }
    input {
      @apply border-b border-b-navy-100 ps-10;
    }
    ul {
      @apply -ms-4;
    }

    .line {
      @apply mx-0 border-t border-t-azure-100;
    }
  }

  .search-icon {
    @apply stroke-navy-100;
  }
  &:first-child {
    .wrapper {
      @apply border border-navy-100 bg-azure-10;
      input {
        @apply ps-10 placeholder:text-navy-100/50;
      }
    }
  }
}
