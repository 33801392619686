#helpContent {
  @apply text-black;
  h2,
  h3,
  h4,
  h5 {
    @apply mb-2 font-medium pt-4 first:pt-0 last:mb-0;
  }
  h1 {
    @apply text-2xl border-b font-medium border-b-gray-200 mb-4 pb-4;
    &.article-title {
      @apply pt-0;
    }
  }
  h2 {
    @apply text-xl border-b  border-b-gray-200 pb-4;
  }
  h3 {
    @apply text-lg;
  }

  h4,
  h5 {
    @apply text-base;
  }

  img {
    @apply mb-4 p-2 bg-gray-50 border border-gray-100;
  }

  ul {
    @apply list-disc ml-6 mb-4;
    li {
      @apply text-sm mb-2;
    }
  }

  ol {
    @apply list-decimal ml-6 mb-4;
    li {
      @apply text-sm mb-2;
    }
  }

  a {
    @apply text-blue-600 hover:underline;
  }

  table {
    @apply w-full border-collapse overflow-hidden mb-8;
  }

  tbody th {
    @apply px-2 py-2 text-left text-sm font-medium text-black uppercase border-b border-gray-300 bg-gray-200;
  }

  tbody tr {
    @apply hover:bg-gray-100 even:bg-gray-50;
  }

  tbody td {
    @apply px-2 py-2 text-sm text-black border-b border-gray-300;
  }

  tbody tr:hover td {
    @apply text-gray-800;
  }

  code {
    @apply bg-gray-100 hover:bg-gray-200 px-2 py-0.5 inline-block;
  }

  .confluence-information-macro {
    @apply flex items-start bg-blue-50 border border-blue-300 rounded-lg px-4 py-2 my-4;
  }

  .confluence-information-macro-information {
    @apply flex flex-col;
  }

  .confluence-information-macro-icon {
    @apply text-blue-500 mr-3 mt-1 hidden;
  }

  .confluence-information-macro-body {
    @apply text-blue-700 text-sm;
  }

  .confluence-embedded-file-wrapper {
    @apply block;
  }
}
