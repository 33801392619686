// @layer components {
.document {
  @apply mb-8 max-w-[1024px];

  // p + h2, p + h3 {
  //   @apply text-red-600;
  // }

  h2 + p,
  h3 + p,
  h4 + p {
    @apply pt-4;
  }

  h2 + ul,
  h3 + ul,
  h4 + ul {
    @apply pt-4;
  }

  h1,
  h2,
  h3,
  h4 {
    &.with-border {
      @apply relative -ms-6 border-s-8 border-solid border-navy-100 ps-6;
    }

    &:not(.with-border) {
      @apply ps-2;
    }
  }

  ul > li > h1,
  h2,
  h3,
  h4 {
    &:not(:first-of-type) {
      @apply mt-8;
    }
  }

  // ul > li > p {
  //   @apply pt-0;
  // }

  // ul:has(li):has(p) {
  //   @apply pl-10;

  //   p {
  //     @apply pl-0;
  //   }
  // }

  h1 {
    @apply headline-lg;
  }

  h2 {
    @apply headline-md;
  }

  h3 {
    @apply headline-sm;
  }

  h4 {
    @apply headline-xs;
  }

  > p {
    @apply ps-2;

    // &:first-of-type:not(:empty){
    //   @apply pt-8;
    // }
  }

  // https://stackoverflow.com/questions/38026714/youtube-embed-dynamic-size-with-min-and-max-size
  > .video-wrapper {
    width: 100%;
    max-width: 768px; // set max width of embedded video
    .video-container {
      position: relative;
      overflow: hidden;
      height: 0;
      padding-bottom: 56.25%;

      iframe,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }
  }

  > p:empty {
    @apply pt-4;
  }

  b,
  strong {
    @include fontMedium;
    @apply font-semibold;
  }

  > img {
    @apply w-auto py-8;
  }

  hr {
    &:not(:first-of-type) {
      @apply mt-8;
    }

    @apply mb-8 border-[0.1px] border-solid border-white-90;
  }

  ul,
  ol {
    @apply mb-4 ps-8;
  }

  ul {
    @apply list-disc;
    &.custom-bullets {
      @apply list-none ps-0;
    }
  }

  ol {
    @apply list-decimal;
    ol {
      @apply list-[upper-alpha];
      ol {
        @apply list-[lower-roman];
      }
    }
  }

  // Spacing between embedded entries
  [component-name="nWidget"],
  [component-name="nChip"] {
    @apply mb-4 last:mb-0;
  }

  // Inside a document, assume the nChip font size should match the document
  [component-name="nChip"] {
    @apply [&:not(:first-child)]:ml-0; // Inside a document, assume the chip won't need spacing on the left, as you can add space using RTE
    .n-chip-title {
      @apply text-[length:initial];
    }
  }

  h2 + hr,
  h3 + hr,
  h4 + hr {
    margin-top: 10px !important;
  }
}
