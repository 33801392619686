@layer components {
  .n-tile {
    @apply rounded-lg relative flex h-full transition-all duration-200;

    .text-5xl {
      @apply text-[2rem] xl:text-[3rem];
    }

    .text-6xl {
      @apply text-[2rem] xl:text-[3rem] 2xl:text-[4rem];
    }

    .text-7xl {
      @apply text-[3rem] xl:text-[4rem] 2xl:text-[5rem];
    }

    &:is(a) {
      @apply no-underline;
      // TODO: add theme based hover styles
    }

    &-iconslot {
      @apply relative flex w-auto shrink-0 overflow-hidden;
    }

    &-icon {
      @apply w-auto object-contain min-h-12;
    }

    &-imageslot {
      @apply relative flex w-auto flex-shrink-0 overflow-hidden;
    }

    &-image {
      @apply h-full object-contain;
    }

    &-image-icon {
      @apply max-h-12 w-fit;
    }

    &-image-auto {
      @apply w-auto;
    }

    &-image-full {
      @apply mb-4 w-full;
    }

    &-image-max {
      @apply mb-4 max-h-full;
    }

    &-image-fill {
      @apply max-h-min h-min;
    }

    // &-icon-icon {
    //   @apply min-h-12;
    // }

    &-icon-max {
      @apply h-full min-h-full;
    }

    &-smalltext {
      @apply mb-1;
    }

    &-title {
      @apply mb-1.5 mt-1.5 lg:mt-0;
    }

    &-description {
      @apply mb-0; //mt-2 removed
    }

    &-buttons {
      @apply flex flex-col flex-wrap gap-2 pt-4 lg:flex-row w-max self-center;

      .n-button {
        @apply mx-auto;
      }
    }

    // * Customisations

    // Sizes
    &-xxs {
      @apply p-2;
    }

    &-xs {
      @apply p-4;
    }

    &-sm {
      @apply p-6;
    }

    &-md {
      @apply p-8;
    }

    &-lg {
      @apply p-12;
    }

    &-xl {
      @apply p-16;
    }

    // Alignments
    &-alignment-left {
      @apply flex-col;
    }

    &-alignment-center {
      @apply flex-col items-center text-center;
    }

    &-alignment-icon-on-left {
      @apply flex-row items-center gap-x-6;

      p {
        @apply text-start;
      }
    }

    &-alignment-icon-on-right {
      @apply flex-col lg:flex-row-reverse items-center justify-between gap-x-8;
    }

    // * Rich text
    ul {
      @apply list-disc pl-4;
    }
  }
}
