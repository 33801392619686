// ? The reasoning behind this experimental theming is because when nesting theme classes, due to the way css works
// ? the last class in the css file will always take precedence. This was causing the accent-stroke & fill to not get overriden.
// ? By creating mixins that apply the theme classes, we can apply the theme styling to the theme class and add a sibling selector
// ? to increase specificity to the nested component (e.g. nTile inside nCard)

@import "buttons";
@import "tables";

@mixin theme-white-100 {
  @apply bg-white-100 text-navy-100;

  .accent-border {
    @apply border-navy-100;
  }

  .accent-stroke * {
    * {
      @apply stroke-navy-100;
    }

    // ? To use accent-stroke-to-fill, apply the class to the nIcon SVG file you want to fill.
    .accent-stroke-to-fill {
      @apply fill-navy-100;
    }
  }

  .accent-fill * {
    @apply fill-navy-100;
  }

  .accent-bullet {
    @apply stroke-azure-100;
  }

  .btn-primary {
    @include btn-primary-default;
  }

  .btn-secondary {
    @include btn-secondary-default;
  }

  .btn-tab {
    @include btn-tab-default;
  }

  @include table-on-white-100;
}

@mixin theme-white-90 {
  @apply bg-white-90 text-navy-100;

  .accent-border {
    @apply border-navy-100;
  }

  .accent-stroke {
    * {
      @apply stroke-navy-100;
    }

    .accent-stroke-to-fill {
      @apply fill-navy-100;
    }
  }

  .accent-fill * {
    @apply fill-navy-100;
  }

  .accent-bullet {
    @apply stroke-azure-100;
  }

  .btn-primary {
    @include btn-primary-default;
  }

  .btn-secondary {
    @include btn-secondary-default;
  }

  .btn-tab {
    @include btn-tab-default;
  }

  @include table-on-white-90;
}

@mixin theme-white-85 {
  @apply bg-white-85 text-navy-100;

  .accent-border {
    @apply border-navy-100;
  }

  .accent-stroke * {
    @apply stroke-navy-100;
  }

  .accent-fill * {
    @apply fill-navy-100;
  }

  .accent-bullet {
    @apply stroke-azure-100;
  }

  .btn-primary {
    @include btn-primary-default;
  }

  .btn-secondary {
    @include btn-secondary-default;
  }

  .btn-tab {
    @include btn-tab-default;
  }
}

@mixin theme-azure-10 {
  @apply bg-azure-10 text-navy-100;

  .accent-border {
    @apply border-azure-100;
  }

  .accent-stroke * {
    @apply stroke-azure-100;
  }

  .accent-fill * {
    @apply fill-azure-100;
  }

  .accent-bullet {
    @apply stroke-azure-100;
  }

  .btn-primary {
    @include btn-primary-default;
  }

  .btn-secondary {
    @include btn-secondary-default;
  }

  .btn-tab {
    @include btn-tab-default;
  }
}

@mixin theme-navy-70 {
  @apply bg-navy-70 text-white-100;

  .accent-border {
    @apply border-white-100;
  }

  .accent-stroke {
    * {
      @apply stroke-white-100;
    }

    .accent-stroke-to-fill {
      @apply fill-white-100;
    }
  }

  .accent-fill * {
    @apply fill-white-100;
  }

  .accent-bullet {
    @apply stroke-white-100;
  }

  .btn-primary {
    @include btn-primary-default;
  }

  .btn-secondary {
    @include btn-secondary-on-dark;
  }

  .btn-tab {
    @include btn-tab-default;
  }

  // TODO: review if we need theme based anchor hover styles
  &:is(a) {
    @apply hover:text-white-90;
  }

  @include table-on-navy-70;
}

@mixin theme-navy-100 {
  @apply bg-navy-100 text-white-100;

  .accent-border {
    @apply border-white-100;
  }

  .accent-stroke {
    * {
      @apply stroke-white-100;
    }

    .accent-stroke-to-fill {
      @apply fill-white-100;
    }
  }

  .accent-fill * {
    @apply fill-white-100;
  }

  .accent-bullet {
    @apply stroke-white-100;
  }

  .btn-primary {
    @include btn-primary-default;
  }

  .btn-secondary {
    @include btn-secondary-on-dark;
  }

  .btn-tab {
    @include btn-tab-default;
  }

  @include table-on-navy-100;
}

@mixin theme-transparent-light {
  @apply bg-transparent text-navy-100;

  .accent-border {
    @apply border-navy-100;
  }

  .accent-stroke * {
    @apply stroke-navy-100;
  }

  .accent-fill * {
    @apply fill-navy-100;
  }

  .accent-bullet {
    @apply stroke-navy-100;
  }

  .btn-primary {
    @include btn-primary-on-bg;
  }

  .btn-secondary {
    @include btn-secondary-on-bg;
  }

  .btn-tab {
    @include btn-tab-on-transparent-light;
  }

  @include table-on-transparent-light;
}

@mixin theme-transparent-dark {
  @apply bg-transparent text-white-100;

  .accent-border {
    @apply border-white-100;
  }

  .accent-stroke * {
    @apply stroke-white-100;
  }

  .accent-fill * {
    @apply fill-white-100;
  }

  .accent-bullet {
    @apply stroke-white-100;
  }

  .btn-primary {
    @include btn-primary-on-bg;
  }

  .btn-secondary {
    @include btn-secondary-on-bg;
  }

  .btn-tab {
    @include btn-tab-default;
  }

  @include table-on-transparent-dark;
}

.theme-white-100 {
  @include theme-white-100;

  &[component-name="nTile"] {
    @include theme-white-100;
  }
}

.theme-white-90 {
  @include theme-white-90;

  &[component-name="nTile"] {
    @include theme-white-90;
  }
}

.theme-white-85 {
  @include theme-white-85;

  &[component-name="nTile"] {
    @include theme-white-85;
  }
}

.theme-azure-10 {
  @include theme-azure-10;

  &[component-name="nTile"] {
    @include theme-azure-10;
  }
}

.theme-navy-70 {
  @include theme-navy-70;

  &[component-name="nTile"] {
    @include theme-navy-70;
  }
}

.theme-navy-100 {
  @include theme-navy-100;

  &[component-name="nTile"] {
    @include theme-navy-100;
  }

  // TODO: review if this needs to be strictly only for tiles
  &:is(a) {
    @apply hover:text-azure-40;
  }
}

.theme-transparent-light {
  @include theme-transparent-light;

  &[component-name="nTile"] {
    @include theme-transparent-light;
    @apply xl-max:px-0;
  }
}

.theme-transparent-dark {
  @include theme-transparent-dark;

  &[component-name="nTile"] {
    @include theme-transparent-dark;
  }
}

.n-megamenu,
.n-megamenu-mobile {
  .btn-primary {
    @include btn-primary-default;
  }

  .btn-secondary {
    @include btn-secondary-default;
  }
}

// Put branding based styling at bottom to force the branding to take precedence
.btn-primary {
  &.btn-invest {
    @include btn-primary-invest;
  }

  &.btn-connect {
    @include btn-primary-connect;
  }
}

.btn-primary-invest {
  @include btn-primary-invest;
}

.btn-primary-alpha {
  @include btn-primary-alpha;
}

.btn-secondary-invest {
  @include btn-secondary-invest;
}

.btn-primary-connect {
  @include btn-primary-connect;
}

// Accent Border Based Styling.
// ? These override borders set by the theme. The cn() function will replace the theme based class with the below when selected.
// ? Example use: nTitle title border can be overridden.
.accent-border-navy-100 {
  @apply border-navy-100;
}

.accent-border-azure-100 {
  @apply border-azure-100;
}

.accent-border-navy-70 {
  @apply border-navy-70;
}

.accent-border-invest-300 {
  @apply border-invest-300;
}

.accent-border-stone-600 {
  @apply border-stone-600;
}

// .accent-bullet-invest-300 {
//   @apply stroke-invest-300;
// }

// Accent Stroke Based Styling.
// ? These overrides only work for primary & secondary buttons. Apply the class directly as a sibling to the button.
.btn-primary,
.btn-secondary {
  &.accent-stroke-white {
    * {
      @apply stroke-white;
    }
  }
}
