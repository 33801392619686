// ? nSection uses a grid system separate from nGrid. nSection is limited to maximum 3 columns.
// ? When nTitle is used in the first column, it will span the full width of the grid on tablet.

@layer components {
  .n-section {

    .n-section-wrapper {
      @apply main-section;
    }

    .n-section-grid-cols-1 {
      @apply grid-cols-1;
    }

    .n-section-grid-cols-2 {
      @apply grid-cols-1 sm:grid-cols-2;

      // Used when the first column is nTitle
      &.n-section-grid-with-full-title {
        > div {
          @apply lg-max:col-span-full;
        }
      }
    }

    .n-section-grid-cols-3 {
      @apply sm:grid-cols-2 lg:grid-cols-3;

      // Used when the first column is nTitle
      &.n-section-grid-with-full-title {
        @apply sm:lg-max:grid-cols-2;
        > div {
          @apply sm:lg-max:col-auto;
          &:first-child {
            @apply sm:lg-max:col-span-full;
          }
        }
      }
    }

    .n-section-grid-cols-4 {
      @apply sm:grid-cols-2 lg:grid-cols-4;

      // Used when the first column is nTitle
      &.n-section-grid-with-full-title {
        @apply sm:lg-max:grid-cols-2;
        > div {
          @apply sm:lg-max:col-auto;
          &:first-child {
            @apply sm:lg-max:col-span-full;
          }
        }
      }
    }
  }
}
