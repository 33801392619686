// Fonts
@mixin fontRoman {
  font-family: "NeueHaasGrotText-55Roman", "Helvetica", sans-serif;
  font-weight: 400;
  font-display: swap;
}

@mixin fontMedium {
  font-family: "NeueHaasGrotText-65Medium", "Helvetica", sans-serif;
  font-weight: 600;
  font-display: swap;
}

@mixin fontItalic {
  font-family: "NeueHaasGrotText-56Italic", "Helvetica", sans-serif;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: "NeueHaasGrotText-55Roman";
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.cmcmarkets.com/fonts/NeueHaasGrotText-55Roman.otf)
    format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "NeueHaasGrotText-55Roman";
  font-style: italic;
  font-weight: 400;
  src: url(https://assets.cmcmarkets.com/fonts/NeueHaasGrotText-56Italic.otf)
    format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "NeueHaasGrotText-55Roman";
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.cmcmarkets.com/fonts/NeueHaasGrotText-65Medium.otf)
    format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "NeueHaasGrotDisp-55Roman";
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.cmcmarkets.com/fonts/NeueHaasGrotDisp-55Roman.otf)
    format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "NeueHaasGrotDisp-55Roman";
  font-style: italic;
  font-weight: 400;
  src: url(https://assets.cmcmarkets.com/fonts/NeueHaasGrotDisp-56Italic.otf)
    format("opentype");
}
@font-face {
  font-display: swap;
  font-family: "NeueHaasGrotDisp-55Roman";
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.cmcmarkets.com/fonts/NeueHaasGrotDisp-65Medium.otf)
    format("opentype");
}

@layer base {
  .font-semibold,
  .font-bold,
  b,
  strong {
    @include fontMedium;
  }

  .font-italic {
    @include fontItalic;
  }

  // TODO: Graeme: can probably delete this..
  @mixin font-bold {
    font-weight: 500;
  }

  // Headers
  .headline-xl {
    @apply font-heading text-5xl leading-[1.05] sm:text-6xl;
  }
  .headline-lg {
    @apply font-heading text-4xl leading-[1.05] sm:text-5xl;
  }
  .headline-md {
    @apply font-heading text-3xl leading-[1.2] sm:text-4xl;
  }
  .headline-sm {
    @apply font-heading text-[27px] leading-[1.2];
  }
  .headline-xs {
    @apply font-heading text-xl leading-[1.2];
  }
}
