@layer components {
  .n-template {
    [component-name="nTile"] {
      @apply h-min;
    }
  }

  // Apply h-full to tiles inside a grid so they fill the grid (force tiles to have same heights)
  // Reference: https://www.cmcmarkets.com/en-gb/film-stocks
  [component-name="nGrid"] [component-name="nTile"] {
    @apply h-full;
  }
}
