// Theme Based Primary & Secondary Buttons

// ? Button - Primary
@mixin btn-primary-default {
  @apply border border-azure-100 bg-azure-100 stroke-white text-white hover:border-azure-120 hover:bg-azure-120 hover:text-white focus:border-azure-40 active:border-azure-140 active:bg-azure-140 disabled:opacity-40;
  .accent-stroke-to-fill {
    @apply fill-white-100;
  }
}

@mixin btn-primary-on-bg {
  @apply border border-white-100 bg-white-100 stroke-navy-100 text-navy-100 hover:border-white-90 hover:bg-white-90 hover:text-navy-100 focus:border-azure-40 active:bg-neutral-20 disabled:text-navy-100/40;
  .accent-stroke-to-fill {
    @apply fill-navy-100;
  }
  &:disabled {
    * {
      @apply stroke-navy-100/40;
    }
  }
}

// ? Button - Secondary
@mixin btn-secondary-default {
  @apply border border-azure-100 bg-transparent stroke-navy-100 text-navy-100 hover:bg-azure-10 hover:border-azure-120 focus:bg-azure-10 focus:border-azure-40 active:border-azure-140 disabled:opacity-40;
  .accent-stroke-to-fill {
    @apply fill-navy-100;
    * {
      @apply stroke-none;
    }
  }
  * {
    @apply stroke-navy-100;
  }
}

@mixin btn-secondary-on-dark {
  @apply border border-azure-100 bg-transparent stroke-white-100 text-white-100 hover:border-azure-120 hover:bg-azure-100/10 focus:border-azure-40 active:border-azure-140 disabled:opacity-40;
  .accent-stroke-to-fill {
    @apply fill-white-100;
  }
  * {
    @apply stroke-white-100;
  }
}

@mixin btn-secondary-on-bg {
  @apply border border-white-100 bg-black/[1%] stroke-white-100 text-white-100 backdrop-blur-3xl hover:border-white-100 focus:border-azure-40 disabled:opacity-40;
  .accent-stroke-to-fill {
    @apply fill-white-100;
  }
  &:disabled {
    * {
      @apply stroke-white-100/40;
    }
  }
}

// ? Button - Tabs
@mixin btn-tab-default {
  @apply rounded-md border-[1.5px] border-solid border-navy-20 text-sm text-navy-100 hover:border-azure-100 hover:bg-azure-10 hover:text-black;

  &.active {
    //  @apply border-azure-100 bg-azure-10 text-navy-100;
    @apply border-navy-20 bg-navy-20 text-white hover:bg-navy-40 focus:border-azure-10;
  }
}

@mixin btn-tab-on-transparent-light {
  @apply rounded-md border-[1.5px] border-solid border-navy-20 bg-white-100 text-sm text-navy-100 hover:border-azure-100 hover:text-black;

  &.active {
    @apply border-azure-100 bg-azure-10 text-navy-100;
  }
}

// ? Branding Based Styling
@mixin btn-primary-invest {
  @apply border border-invest-300 bg-invest-300 stroke-navy-100 text-navy-100 hover:border-invest-400 hover:bg-invest-400 hover:text-navy-100 focus:border-invest-300 active:border-invest-300 disabled:opacity-40;
}

@mixin btn-secondary-invest {
  @apply border border-invest-300 stroke-navy-100 text-navy-100 hover:border-invest-400 hover:bg-invest-300 hover:text-navy-100 focus:border-invest-300 active:border-invest-300 disabled:opacity-40;
}

@mixin btn-primary-connect {
  @apply border border-connect-orange-100 bg-connect-orange-100 stroke-white-100 text-white-100 hover:border-connect-orange-60 hover:bg-connect-orange-60 focus:border-connect-orange-60 active:border-connect-orange-60 disabled:opacity-40;
}

@mixin btn-primary-alpha {
  @apply border border-stone-600 bg-stone-600 stroke-white-100 text-white-100 hover:border-stone-400 hover:bg-stone-400 focus:border-stone-400 active:border-stone-400 disabled:opacity-40;
}
